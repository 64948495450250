import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HeaderInterceptor } from './shared/interceptor/header.interceptor';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingComponent } from './main/landing/landing.component';
import { RegFormComponent } from './main/reg-form/reg-form.component';
import { ThankYouComponent } from './main/thank-you/thank-you.component';
import { HeaderComponent } from './inc/header/header.component';
import { FooterComponent } from './inc/footer/footer.component';
import {DropdownModule} from 'primeng/dropdown';
import {InputTextModule} from 'primeng/inputtext';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {FileUploadModule} from 'primeng/fileupload';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { DialogModule } from 'primeng/dialog';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { InputMaskModule } from 'primeng/inputmask';
import { PrivacyPolicyComponent } from './main/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './main/terms-conditions/terms-conditions.component';
import { NgxScannerQrcodeModule, LOAD_WASM } from 'ngx-scanner-qrcode';
import { QrtestComponent } from './main/qrtest/qrtest.component';

// Necessary to solve the problem of losing internet connection
LOAD_WASM().subscribe();

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    RegFormComponent,
    ThankYouComponent,
    HeaderComponent,
    FooterComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    QrtestComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    DropdownModule,
    InputTextModule,
    ButtonModule,
    CalendarModule,
    FileUploadModule,
    DynamicDialogModule,
    DialogModule,
    InputMaskModule,
    ToggleButtonModule,
    NgxScannerQrcodeModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    DialogService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
