import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment.development';
import { ApiResponse } from 'src/app/interface/common/common';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  regApi: string = environment.apiBaseUrl + 'reg';
  modellistApi: string = environment.apiBaseUrl + 'modelList';

  constructor(
    private commonService: CommonService,
    private http: HttpClient
  ) { }

  
  warrantyRegApi(data:any): Observable<ApiResponse> {

    const formData = this.commonService.convertJsonToFormData(data);
    return this.http.post<ApiResponse>(this.regApi, formData);
  }
  modelList(): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.modellistApi, {});
  }

}
