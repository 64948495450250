import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl  } from '@angular/forms';
import { ContentService } from 'src/app/service/content/content.service';
import { formatDate } from '@angular/common';
import { NgxScannerQrcodeService, ScannerQRCodeSelectedFiles, ScannerQRCodeConfig } from 'ngx-scanner-qrcode';

@Component({
  selector: 'app-reg-form',
  templateUrl: './reg-form.component.html',
  styleUrls: ['./reg-form.component.scss']
})
export class RegFormComponent {
  modelList:any[] = [];
  selectedModelList: any;
  selectedSerialNo: any;
  purchaseDate: any;
  finalFormData: any;

  disableSerialNoInput: boolean = false;

  QRvisible: boolean = false;
  qrAddModelBtn: boolean = true;
  qrResult_model: any;
  qrResult_serialno: any;
  qrResult: any;
  camChecked: any = 'back';
  action: any;

  productList:any[] = [];

  maxDate = new Date();

  selectedStateOption: any = '';
  stateOption:any[] = [
    {name:'Selangor', val:'SEL'},
    {name:'Kuala Lumpur', val:'KL'},
    {name:'Negeri Sembilan', val:'N9'},
    {name:'Johor', val:'JHR'},
    {name:'Melaka', val:'MLK'},
    {name:'Pahang', val:'PHG'},
    {name:'Terengganu', val:'TGN'},
    {name:'Kelantan', val:'KEL'},
    {name:'Perlis', val:'PER'},
    {name:'Kedah', val:'KDH'},
    {name:'Pulau Pinang', val:'PNG'},
    {name:'Perak', val:'PRK'},
    {name:'Sabah', val:'SBH'},
    {name:'Sarawak', val:'SWK'}
  ];

  form:FormGroup;
  emailValidator: boolean = true;

  @ViewChild('inputFile') inputFileRef!: ElementRef;

  constructor(
    private fb: FormBuilder,
    private contentService: ContentService
  ){
    
    this.form = this.fb.group({
      product_array:[[],[Validators.required]],
      fname:['',[Validators.required]],
      lname:['',[Validators.required]],
      email:['',[Validators.required]],
      mobileno:['',[Validators.required]],
      address1:['',[Validators.required]],
      address2:[''],
      city:['',[Validators.required]],
      postcode:['',[Validators.required]],
      state:['',[Validators.required]],
      purchaseDate:['',[Validators.required]],
      purchaseFrom:['',[Validators.required]],
      purchaseProof:[''],
    })
    // console.log(this.form.value)
  this.getModelList();
    
    // this.warrantyReg();
  }


  onAddModel(){
    
    if(this.selectedModelList == null || this.selectedModelList == ''){
      alert('Model cannot be empty!');
      return;
    }
    if(this.disableSerialNoInput == false && this.selectedSerialNo == null){
      alert('Serial Number/Batch Number cannot be empty!');
      return;
    }
    if(this.disableSerialNoInput == false && this.selectedSerialNo.length != 9){
      alert('Requires 9 Digits Serial Number/Batch Number');
      return;
    }
    if(this.disableSerialNoInput == false && !this.isNumber(this.selectedSerialNo)){
      alert('Requires 9 Digits Numbers only');
      return;
    }
    // Check duplicate serial no if required serial no
    let selectedItem = this.modelList.find(model => model.modelname === this.selectedModelList);
    if(selectedItem.requiredSerial != 0){
      let foundObject = this.productList.find(item => item.serialNo === this.selectedSerialNo);
      if (foundObject) {
        alert('Serial Number '+this.selectedSerialNo+' already added');
        return;
      }
    }
    // let foundObject = this.productList.find(item => item.serialNo === this.selectedSerialNo);
    // if (foundObject) {
    //   alert('Serial Number '+this.selectedSerialNo+' already added');
    //   return;
    // }

    // let item : any= [];
    let item = {
      modelNo:this.selectedModelList,
      serialNo:this.selectedSerialNo
    }

    this.productList.push(item);
    
    //max equipment 20
    if(this.productList.length >= 20){
      alert('Maximum 20 Serial Number allowed.');
      return;
    }
  

    // console.log(this.productList)
    this.selectedModelList=''
    this.selectedSerialNo=''
  }
  onRemoveModel(index:number){
    // alert(id+'Hey!');
    this.productList.splice(index, 1);
  }
  onUploadDocument(files: any) {
    console.log(files)
    //  alert(files.item(0).size)
    if(files.target.files.item(0)?.size<5242880) //5mb - bytes
    {
      this.form.patchValue({
        purchaseProof: files.target.files.item(0)
      });
      console.log(files.target.files.item(0))
      //this.formParticipant=URL.createObjectURL(files.target.files[0]);
    }
    else{
      
      alert('The Image need to below 5MB.');
      this.inputFileRef.nativeElement.value = '';
    }
  }

  onCheckRequiresSerialNo(event:any){
    let selectedItem = this.modelList.find(model => model.modelname === event);
    //disable serialno input if val == 0
    if(selectedItem.requiredSerial == 0){
      this.disableSerialNoInput = true;
    } else {
      this.disableSerialNoInput = false;
    }

  }
  
  warrantyReg(){
    // if(!this.purchaseDate){
    //   alert('Please fill all required fields.')
    //   return;
    // }



    this.form.patchValue({product_array:JSON.stringify(this.productList)})


    this.finalFormData = this.form.value;
      if(this.purchaseDate){
        this.form.patchValue({purchaseDate:this.purchaseDate})
        this.finalFormData.purchaseDate = formatDate(new Date(this.finalFormData.purchaseDate), 'yyyy-MM-dd H:mm:ss', 'en-my')
      }


     console.log(this.finalFormData)
    let match = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.exec(this.finalFormData.email);
    if(match){
      // console.log(emailValidator); // "TypeScript"
      this.emailValidator = true;
    } else {
      this.emailValidator = false;
      //return;
    }
    // check all form if empty
    let hasEmptyField =  Object.values(this.finalFormData).some(value => value !== this.finalFormData.address2 && !value);
    if (hasEmptyField) {
      alert('Please fill all required fields.')
        return;
    }
    if(this.finalFormData.product_array.length == 2){
      alert('Please fill all required fields.')
      return;
    }
    if(this.finalFormData.fname == ''){
      alert('Please fill all required fields.')
      return;
    }
    if(this.finalFormData.lname == ''){
      alert('Please fill all required fields.')
      return;
    }
    if(this.finalFormData.email == ''){
      alert('Please fill all required fields.')
      return;
    }
    if(this.finalFormData.mobileno == ''){
      alert('Please fill all required fields.')
      return;
    }
    if(this.finalFormData.address1 == ''){
      alert('Please fill all required fields.')
      return;
    }
    if(this.finalFormData.city == ''){
      alert('Please fill all required fields.')
      return;
    }
    if(this.finalFormData.postcode == ''){
      alert('Please fill all required fields.')
      return;
    }
    if(this.finalFormData.state == ''){
      alert('Please fill all required fields.')
      return;
    }
    if(this.finalFormData.purchaseDate == ''){
      alert('Please fill all required fields.')
      return;
    }
    if(this.finalFormData.purchaseFrom == ''){
      alert('Please fill all required fields.')
      return;
    }
    if(this.finalFormData.purchaseProof == ''){
      alert('Please fill all required fields.')
      return;
    }

    if(this.emailValidator == false){
      alert('Invalid email format.')
      return;
    }




    this.contentService.warrantyRegApi(this.finalFormData).subscribe((response) => {

      if(!response) {
        alert(response)
        return;
      }
      if(!response.success) {
        alert(response.message)
        return;
      }
      if(response.success) {
      this.form.reset();
        window.open(
          'thankyou',
          '_self'
        );
      }
    });
  }

  getModelList(){
    this.contentService.modelList().subscribe((response) => {
      if(!response) {
        alert(response)
        return;
      }
      if(response.success) {
      // console.log(response.data);
       this.modelList = response.data;
      }
    });
  }

  

  isNumber(value: string): boolean {
    return /^\d+$/.test(value);
  }

  public config: ScannerQRCodeConfig = {
    constraints: { 
      video: {
        facingMode: {
          exact: 'environment'
        }
      }
    } 
  };
  
  onChangeCam(e:any) {
    // this.camChecked = e.checked;
    // alert(this.camChecked)
    if(e.checked){
      this.camChecked = 'front'
    } else {
      this.camChecked = 'back'
    }
  }

  openQrScanner(){
    this.QRvisible = true;
    
  }
  getValueQR(qrData:any){
    // qrData.isStart = true;
    // console.log(qrData);
    // console.log(qrData.data.value[0]?.value.split(","));
   this.qrResult = qrData.data.value[0]?.value.split(",");
   if(this.qrResult){
    this.qrResult_model = this.qrResult[1];
    this.qrResult_serialno = this.qrResult[2];
    this.qrAddModelBtn = false;
   }

    if (qrData.data.value[0]?.value){
      qrData.isStart = false;
    }
  }
  qrAddModel(){

       let item = {
        modelNo:this.qrResult_model,
        serialNo:this.qrResult_serialno
      }

      // Check duplicate serial no
      let foundObject = this.productList.find(item => item.serialNo === this.qrResult_serialno);
      if (foundObject) {
        alert('Serial Number '+this.qrResult_serialno+' already added');
        return;
      }
      
      this.productList.push(item);

      //max equipment 20
      if(this.productList.length >= 20){
        alert('Maximum 20 Serial Number allowed.');
        return;
      }

      this.qrResult_model = '';
      this.qrResult_serialno = '';

      //close QR
      this.QRvisible = false;
      this.action ='';
  }
}
