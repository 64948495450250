
<section class="page-content">
    <div class="container">
        <h4 class="mb-3">Privacy Policy</h4>

        <p>Last Updated: 09/01/2024</p>
        
        <p>KDK ("us", "we", or "our") operates the [Your Website/Service] website (the "Service"). This page informs you of our policies regarding the collection, 
            use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>
        
        <h6>1. Information Collection and Use</h6>
        
        <p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>
        
        <p>Types of Data Collected:</p>
        <ul class="list-unstyled">
            <li>- Personal Data: While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact 
                or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:</li>
            <li>- Email address</li>
            <li>- First name and last name</li>
            <li>- Phone number</li>
            <li>- Address, State, Province, ZIP/Postal code, City</li>
            <li>- Cookies and Usage Data</li>
        </ul>

        
        <h6>2. Use of Data</h6>
        
        <p>We use the collected data for various purposes:</p>
        <ul class="list-unstyled">
            <li>- To provide and maintain our Service</li>
            <li>- To notify you about changes to our Service</li>
            <li>- To provide customer support</li>
            <li>- To gather analysis or valuable information so that we can improve our Service</li>
            <li>- To monitor the usage of our Service</li>
            <li>- To detect, prevent and address technical issues</li>
        </ul>
        
        <h6>3. Transfer of Data</h6>
        
        <p> Your information, including Personal Data, may be transferred to—and maintained on—computers located outside of your state, province, country, 
            or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.
            <br/>
            If you are located outside Malaysia and choose to provide information to us, please note that we transfer the data, including Personal Data, 
            to Malaysia and process it there.</p>
        
        <h6>4. Disclosure of Data</h6>
        
        <p>We may disclose your Personal Data in the good faith belief that such action is necessary to:</p>
        <ul class="list-unstyled">
        <li>- Comply with a legal obligation</li>
        <li>- Protect and defend the rights or property of KDK</li>
        <li>- Prevent or investigate possible wrongdoing in connection with the Service</li>
        <li>- Protect the personal safety of users of the Service or the public</li>
        <li>- Protect against legal liability</li>
        </ul>
        <h6>5. Security of Data</h6>
        
        <p>The security of your data is important to us, but remember that no method of transmission over the Internet or method of electronic storage is </p>
    </div>
</section>
