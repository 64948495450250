<p>qrtest works!</p>
<!-- For camera -->
<ngx-scanner-qrcode #action="scanner"></ngx-scanner-qrcode>

<!-- data  -->
<!-- <span>{{ action.data.value | json }}</span> -->
<!-- value -->
<br/><br/>
<!-- <span>{{ action.data | async | json }}</span> -->
<!-- async -->
{{getValue(action)}}
{{this.qrValue}}
<!-- Loading -->
<p *ngIf="action.isLoading">⌛ Loading...</p>
<!-- <button *ngIf="action.data.value? action.stop() : action.start()" ></button> -->

<!-- start -->
<button (click)="action.isStart ? action.stop() : action.start()">{{action.isStart ? 'Stop' : 'Start'}}</button>