<section class="page-content">
    <div class="container">
        <h4 class="mb-3">  Terms and Conditions</h4>

        <h6>1. Acceptance of Terms</h6>
        <p>By accessing or using this website, you agree to comply with and be bound by these terms and conditions. If you do not agree to these terms, please do not use this website.</p>
        
        <h6>2. Use of Content</h6>
        <p>All content on this website is for informational purposes only. The content may be changed or updated without notice. Use the information at your own risk.</p>
        
        <h6>3. User Accounts</h6>
        <p>You may be required to create an account to access certain features of the website. You are responsible for maintaining the confidentiality of your account information.</p>
        
        <h6>4. Privacy Policy</h6>
        <p>Your use of this website is also governed by our Privacy Policy. Please review the Privacy Policy to understand our practices.</p>
        
        <h6>5. Prohibited Conduct</h6>
        <p>You agree not to engage in any conduct that may disrupt or interfere with the proper functioning of this website. Prohibited conduct includes but is not limited to hacking, transmitting viruses, or violating applicable laws.</p>
        
        <h6>6. Intellectual Property</h6>
        <p>All content and materials on this website are the property of KDK. You may not use, reproduce, or distribute any content without permission.</p>
        
        <h6>7. Disclaimer of Warranties</h6>
        <p>This website is provided "as is" without any warranties, express or implied. KDK makes no representations or warranties regarding the accuracy or completeness of the information.</p>
        
        <h6>8. Limitation of Liability</h6>
        <p>In no event shall KDK be liable for any damages, including without limitation, indirect or consequential damages.</p>
        
        <h6>9. Governing Law</h6>
        <p>These terms and conditions are governed by and construed in accordance with the laws of [Your Jurisdiction].</p>
        
        <h6>10. Changes to Terms</h6>
        <p>KDK reserves the right to change these terms and conditions at any time without notice. Your continued use of the website constitutes acceptance of the updated terms.</p>
        
        <p>If you have any questions or concerns about these terms and conditions, please contact us at [Your Contact Information]. </p>       
    </div>
</section>
