<section class="page-content">
    <div class="container">
        <h4 class="mb-3">Register Your KDK Product Warranty Today!</h4>
        <p><i>Fans for Life!</i></p>
        <p><strong>Warranty registration process:</strong></p>
        <ol>
            <li><strong>Click the button:</strong>  Register now.</li>
            <li><strong>Fill in Details:</strong> Enter product information, purchase details, personal information.</li>
            <li><strong>Submit:</strong> Click a button to submit.</li>
        </ol>
        <p>Registering warranty is a simple step towards ensuring our valued customer enjoys full benefit from the warranty.</p>
        <div class="register-btn">
            <a routerLink="/registration">Register Now</a>
        </div>
    </div>
</section>