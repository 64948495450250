import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingComponent } from './main/landing/landing.component';
import { RegFormComponent } from './main/reg-form/reg-form.component';
import { ThankYouComponent } from './main/thank-you/thank-you.component';
import { TermsConditionsComponent } from './main/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './main/privacy-policy/privacy-policy.component';
import { QrtestComponent } from './main/qrtest/qrtest.component';

const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
  },
  {
    path: 'registration',
    component: RegFormComponent,
  },
  {
    path: 'thankyou',
    component: ThankYouComponent,
  },
  {
    path: 'terms-and-conditions',
    component: TermsConditionsComponent,
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'qrtest',
    component: QrtestComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
