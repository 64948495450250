import { Component } from '@angular/core';
import { NgxScannerQrcodeModule, NgxScannerQrcodeService, NgxScannerQrcodeComponent } from 'ngx-scanner-qrcode';

@Component({
  selector: 'app-qrtest',
  templateUrl: './qrtest.component.html',
  styleUrls: ['./qrtest.component.scss']
})
export class QrtestComponent {

  qrValue: any;
  constructor(
  ){
  
  }
  getValue(scandata:any){

    console.log(scandata);
    console.log(scandata.data.value[0]?.value.split(","));
    this.qrValue = scandata.data.value[0]?.value;
    if (scandata.data.value[0]?.value){
      scandata.isStart = false;
    }
  }

}
