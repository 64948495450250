<section class="page-content">
    <div class="container">
        <h4 class="mb-3">Registration Completed!</h4>
        <p>Thank you for taking the time to register the warranty for your KDK product.</p>
        <p>Should you need any assistance regarding your product, please do not hesitate to contact your nearest service 
            agents at <a target="_blank" [href]="'https://www.kdk.com.my/customer-services/service-agent/peninsular-malaysia/'">https://www.kdk.com.my/customer-services/service-agent/peninsular-malaysia/</a></p>
        <p>Your support allows us to continue delivering reliable products for many years to come.</p>
        <div class="register-btn">
            <a routerLink="/registration">Register Another Products</a>
        </div>
    </div>
</section>
