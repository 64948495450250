import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.development';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private titleService: Title,
  ) { }

  updateTitle(title: string = ''): void {
    this.titleService.setTitle(`${title} - ${environment.appName}`);
  }

  convertJsonToFormData(jsonObj: any): FormData {
    const formData = new FormData();
    Object.keys(jsonObj).forEach(key => {
    // Convert null values to an empty string
    const value = jsonObj[key] === null ? "" : jsonObj[key];
    formData.append(key, value);
  });
  return formData;
    // const formData = new FormData();
    // Object.keys(jsonObj).forEach(key => formData.append(key, jsonObj[key]));
    // return formData;
  }
}
